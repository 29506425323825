<template>
	<div class="container">
		<Header ref="headerBar"></Header>
		<div class="banner-wrap-bg">
			<div class="banner-wrap slider">
				 <transition-group name="fade" tag="div">
				    <div class="slider-item" v-for="(item, i) in banners" :key="i" :class="index==i?'active':''">
					<!-- 	<img :src="item.url"> -->
						<div class="i-bannner-con clearfix">
							<div class="i-bannner-left fl">
								<h2 @click="bannerTxtLink(item)">{{item.title}}</h2>
								<div class="i-bannner-left-text">
									<p>{{item.pagedesc}}</p>
								</div>
							</div>
							<div class="i-bannner-right fr">
								<div class="i-bannner-list" v-for="(item2,p) in item.brandList" :key="p" @click="bannerLink(item2)">
									<div class="i-bannner-logo">
										<img :src="imgUrl+item2.brandLogo" alt="" />
									</div>
									<div class="i-bannner-list-tit">{{item2.brandName}}</div>
									<div class="i-bannner-list-zs">品牌指数: {{item2.score/10}}</div>
								</div>
							</div>
						</div>
				    </div>
				</transition-group>
				<div class="banner-dot-box">
					<span class="banner-dot" v-for="(item,m) in banners.length" :key="m" :class="index==m?'active':''" @click="dotBtn(m)"></span>
				</div>
			</div>
			<!-- banner-wrap end -->
		</div><!-- banner-wrap-bg end -->
		
		<div class="i-main mt-50">
			<div class="i-m1 wrap">
				<div class="i-title-box">
					<a href="/category" class="i-title-more" >查看更多 &gt;</a>
					<div class="i-title">
						<img src="../../assets/images/i-tit-icon_03.png"/>
						<h2>品牌排行榜</h2>
					</div>
				</div>
				<div class="i-m1-con mt-15">
					<div class="i-m1-left border-10">
						<div class="i-subTit-box clearfix">
							<div class="i-subTit fl">热门排行榜</div>
							<div class="i-subTit-right fr"></div>
						</div><!-- i-subTit-box end -->
						<div class="i-m1-left-list">
							<ul>
								<li v-for="(item,index) in m1leftLsit" :key="index" @click="hangyeLink(item)">
									<a href="javascript:void(0)">
										<span class="i-m1-left-list-icon">
											<img :src="imgUrl+item.industryTrans" alt="" />
										</span>
										<p>{{item.industryName}}</p>
									</a>
								</li>
							</ul>
						</div>
					</div><!-- i-m1-left end -->
					<div class="i-m1-center border-10">
						<div class="i-subTit-box clearfix">
							<div class="i-subTit fl">品牌榜<img src="../../assets/images/icon-hot-red_03.png" alt="" class="i-subTit-hot"/></div>
							<div class="i-subTit-right fr">
								<div class="i-subTit-cTabHd">
									<span @click="cTabHd" data-index="1" :class="{'active':m1cTabHd == 1}">月榜</span>
									<span @click="cTabHd" data-index="2" :class="{'active':m1cTabHd == 2}">总榜</span>
								</div>
							</div>
						</div><!-- i-subTit-box end -->
						
						<div class="i-m1-center-con mt-15">
							<div class="i-m1-cHd">
								<div class="i-m1-cHd-item fl i-m1-cHd-1">排名</div>
								<div class="i-m1-cHd-item fl i-m1-cHd-2">品牌名</div>
								<div class="i-m1-cHd-item fl i-m1-cHd-3">访问量</div>
							</div>
							<div class="i-m1-cList mt-15">
								<div class="i-m1-cList-group clearfix" v-for="(item,index) in m1centerList" :key="index" @click="pinpaiLink(item)">
									<div class="i-m1-cList-item fl i-m1-cHd-1">
										<i class="i-m1-cList-item-i"></i>
										<span class="i-m1-cList-logo">
											<img :src="imgUrl+item.brandLogo" alt="" />
										</span>
									</div>
									<div class="i-m1-cList-item  i-m1-cList-item2 fl i-m1-cHd-2">
										{{item.brandName}}
									</div>
									<div class="i-m1-cList-item fl i-m1-cHd-3">
										<p>{{item.totalVisits}}</p>
									</div>
								</div>
							</div>
						</div>
					</div><!-- i-m1-center end -->
					
					<div class="i-m1-right border-10">
						<div class="i-m1-rTit">
							<img src="../../assets/images/i-m1-rigtht-tit_03.png" alt="" />
						</div>
						<div class="i-m1-right-con mt-15">
							<div class="i-m1-right-list clearfix">
								<ul>
									<li v-for="(item,index) in m1ritghtLsit" :key="index" @click="cityLink(item)">
										<a href="javascript:void(0)">
											<span class="i-m1-right-list-icon">
												<img :src="imgUrl+item.industryTrans" alt="" />
											</span>
											<p>{{item.industryName}}</p>
										</a>
									</li>
								</ul>
							</div><!-- i-m1-right-list end -->
						</div>
					</div><!-- i-m1-right end -->
				</div>
			</div><!-- i-m1 end -->
			
			<div class="mt-30 i-m2 wrap">
				<div class="i-title-box">
					<a href="/category" class="i-title-more">查看更多 &gt;</a>
					<div class="i-title">
						<img src="../../assets/images/i-tit-icon_07.png"/>
						<h2>品质生活家</h2>
					</div>
				</div>
				<div class="i-m2-con mt-25">
					<ul class="i-m2-list clearfix">
						<li>
							<img src="../../assets/images/i-m2-pic_03.png" alt="" />
							<div class="i-m2-link i-m2-link1">
								<a href="javascript:void(0)" v-for="(item,a) in  m2Ylink" :key="a" @click="lifeLink(item)">{{item.industryName}}</a>
							</div>
						</li>
						<li>
							<img src="../../assets/images/i-m2-pic_05.png" alt="" />
							<div class="i-m2-link i-m2-link2">
								<a href="javascript:void(0)" v-for="(item,b) in  m2Slink" :key="b" @click="lifeLink(item)">{{item.industryName}}</a>
							</div>
						</li>
						<li>
							<img src="../../assets/images/i-m2-pic_07.png" alt="" />
							<div class="i-m2-link i-m2-link3">
								<a href="javascript:void(0)" v-for="(item,c) in  m2Zlink" :key="c" @click="lifeLink(item)">{{item.industryName}}</a>
							</div>
						</li>
						<li>
							<img src="../../assets/images/i-m2-pic_09.png" alt="" />
							<div class="i-m2-link i-m2-link4">
								<a href="javascript:void(0)" v-for="(item,d) in  m2Xlink" :key="d" @click="lifeLink(item)">{{item.industryName}}</a>
							</div>
						</li>
					</ul>
				</div>
			</div><!-- i-m2 end -->
			
			<div class="mt-50 i-m3-wrap">
				<div class="i-m3 wrap">
					<div class="i-title-box">
						<a href="/article" class="i-title-more">查看更多 &gt;</a>
						<div class="i-title">
							<img src="../../assets/images/i-tit-icon_10.png"/>
							<h2>今日TOP榜单</h2>
						</div>
					</div>
					<div class="i-m3-picList clearfix mt-25">
						<ul>
							<li v-for="(item,f) in m3picList" :key="f" @click="topLink(item)">
								<a href="javascript:void(0)">
									<img :src="imgUrl+item.images[0]" alt="" />
									<div class="i-m3-picInfo">
										<h3>{{item.title}}</h3>
										<p>{{item.pageDesc}}</p>
									</div>
								</a>
							</li>
						</ul>
					</div>
					<div class="i-m3-numBox clearfix mt-20">
						<ul>
							<li v-for="(item,g) in  m3numList" :key="g" @click="topLink(item)">
								<a href="javascript:void(0)">
									<!-- <img src="../../assets/images/i-m3-num_03.png" alt="" class="i-m3-numBox-icon"/> -->
									<i class="i-m3-numBox-icon"></i>
									<span>{{item.title}}</span>
								</a>
							</li>
						</ul>
					</div>
				</div><!-- i-m3 end -->
			</div>
	
			<div class="i-m4 mt-35 wrap">
				<div class="i-title-box">
					<!-- <a href="#" class="i-title-more">查看更多 &gt;</a> -->
					<div class="i-title">
						<img src="../../assets/images/i-tit-icon_13.png"/>
						<h2>行业十大排行</h2>
					</div>
				</div>
				<div class="i-m4-listWrap clearfix mt-25" id="classifyScroll">
					<div class="btn-prev i-m4-click" v-on:click="leftClick">
						<span>&lt;</span>
					</div>
					<div class="roll-wrap">
						<div
							class="roll-box classifys"
							:style="{width: (rollCon.length * 424)-22 + 'px',}"
						 >
							<div class="i-m4-list-item border-10 roll-item" v-for="(item,index) in rollCon" :key="index">
								<div class="i-m4-list-title clearfix">
									<!-- <img src="../../assets/images/i-m4-titbg_03.png" alt="" class="i-m4-list-title-bg"/> -->
									<div class="i-m4-list-title-txt fl">
										<h3>{{item.industryName}}</h3>
										<p>{{item.navigation}}</p>
									</div>
									<div class="i-m4-list-title-logo fr">
										<img :src="imgUrl+item.industryTrans" alt="" />
									</div>
								</div>
								<div class="i-m4-listUl" >
									<ul class="i-m4-listUl1">
										<li v-for="(item2,h) in item.lowerLevelCategoryList" :key="h" @click="sdpjLink(item2)">
											<!-- <img src="../../assets/images/i-m4-topIcon_03.png" alt="" class="i-m4-listIcon"/> -->
											<i class="i-m4-listIcon"></i>
											<div class="i-m4-listCon">
												<a href="javascript:void(0)">
													<em>{{item2.totalVisits}}</em>
													<i class="i-m4-list-logo"><img :src="imgUrl+item2.industryTrans" alt="" /></i>
													<span>{{item2.industryName}}</span>
												</a>
											</div>
											
										</li>
									</ul>
								</div>
							</div><!-- i-m4-list-item end -->
						</div>
					</div>
					<div class="btn-next i-m4-click" v-on:click="rightClick">
						<span>&gt;</span>
					</div>
				</div>
			</div><!-- i-m4 end -->
	
			<div class="mt-50 i-m5-wrap">
				<div class="i-m5 wrap">
					<div class="i-title-box">
						<div class="i-title-right fr">
							<ul class="i-title-rTab clearfix">
								<li v-for="(item,h) in m5tab" :key="h" @click="m5tabBtn(item.id)" :class="m5tabIndex == item.id?'active':''" data-id="item.id">{{item.name}}</li>
							</ul>
						</div>
						<div class="i-title">
							<img src="../../assets/images/i-tit-icon_17.png"/>
							<h2>热门品牌推荐</h2>
						</div>
					</div>
					<div class="i-m5-con mt-30">
						<ul class="i-m5-list clearfix">
							<li v-for="(item,i) in m5list" :key="i" @click="hotppLink(item)">
								<a href="javascript:void(0)">
									<!-- <div class="i-m5-list-logo">
										<img :src="imgUrl+item.brandLogo" alt="" />
									</div> -->
									<img :src="imgUrl+item.brandLogo" alt="" />
									<p>{{item.brandName}}</p>
								</a>
							</li>
						</ul>
					</div><!-- i-m5-con end -->
				</div><!-- i-m5 end  -->	
			</div><!-- i-m5-wrap end  -->
	
			<div class="i-m6 wrap">
				<div class="i-title-box">
					<div class="i-title-right fr">
						<ul class="i-title-rTab clearfix">
							<li v-for="(item,j) in m6tab" :key="j" @click="m6tabBtn(item.id)" :class="m6tabIndex == item.id?'active':''">{{item.name}}</li>
						</ul>
					</div>
					<div class="i-title">
						<img src="../../assets/images/i-tit-icon_21.png"/>
						<h2>旅游娱乐<span>出去旅游，不是看风景，而是去寻回自己——最真实的自己</span></h2>
					</div>
				</div>
				<div class="i-m3-picList clearfix mt-25">
					<ul>
						<li v-for="(item,f) in m6picList" :key="f" @click="lyLick(item)">
							<a href="javascript:void(0)">
								<img :src="imgUrl+item.industryIcon" alt="" />
								<div class="i-m3-picInfo">
									<h3>{{item.industryName}}</h3>
									<p>{{item.navigation}}</p>
								</div>
								<i class="i-m3-picList-tag">榜</i>
							</a>
						</li>
					</ul>
				</div>
			</div><!-- i-m6 end -->
	
			<div class="i-m7 wrap clearfix">
				<div class="i-title-box">
					<div class="i-title-right fr"></div>
					<div class="i-title">
						<img src="../../assets/images/i-tit-icon_22.png"/>
						<h2>热门旅游排行榜</h2>
					</div>
				</div>
				<div class="i-m7-con mt-30">
					<div class="i-m7-list" v-for="(item,k) in m7list" :key="k" :class="'i-m7-list'+k">
						<div class="i-m7-list-top border-10">
							<h3>{{item.industryName}}</h3>
							<p><span>{{item.navigation}}</span></p>
						</div>
						<ul class="i-m7-ul">
							<li  v-for="(item2,l) in item.brandList" :key="l" @click="ly2Lick(item2)">
								<i class="i-m7-ul-topNum">
									<img src="../../assets/images/i-m7-topIcon1.png" alt=""  v-if="l==0"/>
									<img src="../../assets/images/i-m7-topIcon2.png" alt=""  v-if="l==1"/>
									<img src="../../assets/images/i-m7-topIcon3.png" alt=""  v-if="l==2"/>
								</i>
								<div class="i-m7-ul-userImg">
									<img :src="imgUrl+item2.brandLogo" alt="" />
								</div>
								<div class="i-m7-ul-info">
									<p>{{item2.brandName}}</p>
									<div class="i-m7-ul-progress" :class="'i-m7-ul-progress'+l"></div>
									<img src="../../assets/images/icon-aquan_03.png" alt="" class="i-m7-ul-info-icon"/>
								</div>
								<div class="i-m7-ul-btnBox">
									<a href="#" class="i-m7-ul-btn">浏览</a>
								</div>
							</li>
						</ul>
					</div>
				</div>
			</div><!-- i-m7 end -->
			
			<div class="i-m8-wrap">
				<div class="i-m8-title">
					<img src="../../assets/images/i-m8-tit_03.png" alt="" />
				</div>
				<div class="i-m8 wrap">
					<div class="i-m8-con">
						<div class="i-m8-list clearfix">
							<ul>
								<li v-for="(item,m) in m8list1" :key="m" @click="countryLink(item)">
									<a href="javascript:void(0)">
										<img :src="imgUrl+item.industryTrans" alt="" />
									</a>
								</li>
							</ul>
						</div>
						<div class="i-m8-list2 clearfix">
							<ul>
								<li v-for="(item,n) in m8list2" :key="n" @click="countryLink(item)">
									<a href="javascript:void(0)">
										<img :src="imgUrl+item.industryTrans" alt="" />
										<div class="i-m8-list2-link">
											{{item.industryName}}<img src="../../assets/images/icon-arrow-yuan_03.png" alt="" />
										</div>
									</a>
								</li>
							</ul>
						</div>
					</div>
				</div><!-- i-m8 end -->
			</div><!-- i-m8-wrap end -->
			
			<div class="i-m9-wrap">
				<div class="i-m9 wrap clearfix">
					<div class="i-m9-item">
						<img src="../../assets/images/i-m9-icon_03.png" alt="" class="i-m9-icon"/>
						<div class="i-m9-info">
							<h4>全球品牌总数</h4>
							<p>300000<em>+</em></p>
						</div>
					</div>
					<div class="i-m9-item">
						<img src="../../assets/images/i-m9-icon_06.png" alt="" class="i-m9-icon"/>
						<div class="i-m9-info">
							<h4>城市精选商家</h4>
							<p>100000<em>+</em></p>
						</div>
					</div>
					<div class="i-m9-item">
						<img src="../../assets/images/i-m9-icon_09.png" alt="" class="i-m9-icon"/>
						<div class="i-m9-info">
							<h4>行业品牌榜单</h4>
							<p>5000<em>+</em></p>
						</div>
					</div>
				</div>
			</div><!-- i-m9-wrap end -->		
		</div><!-- i-main end  -->
		<Footer></Footer>
		<el-backtop style="width:50px;height:50px;">
			<div style="{
          height: 100%;
          width: 100%;
          background: #da0505 ;
          box-shadow: 0 0 6px rgba(0,0,0, .12);
          text-align: center;
          z-index: 9999;
        }">
				<img src="../../assets/images/backtop.png" alt="" style="vertical-align: top;">
			</div>
		</el-backtop>
	</div>
</template>

<script>
	import {banner,hotPaihang,hotPinpai,cityPaihang,shenghuo,todayTop,shida,tuijian,lvyou,guojia} from "../../api/index.js";
	// import {ppSearch,hySearch} from "../../api/search.js";
	import Header from "../../components/header.vue";
	import Footer from "../../components/footer.vue";
	export default {
		name: "index",
		components: {
			Header,
			Footer,
		},
		data() {
			return {
				imgUrl:'http://zgall.com/prod-api',
				// bannerHeight: 0,
				// screenWidth: 0,
				searchKey:"",//搜索关键词
				mobilePop:false,//手机版弹层
				index: 0, //当前显示的是第几张图片
				currImgs: [],//banner
				timer: null,
				duration: 5000,
				bannerArr: [{
						url: require("../../assets/images/i-banner-jiangtai.png"),
						link: "",
					},
					{
						url: require("../../assets/images/i-m2-pic_05.png"),
						link: "",
					},
					{
						url: require("../../assets/images/i-m2-pic_07.png"),
						link: "",
					},
				],
				banners:[],//顶部焦点图
				m1leftLsit: [],//热门排行榜
				m1cTabHd:1,//热门品牌榜 1月  2总
				m1centerList:[],//热门品牌榜
				m1ritghtLsit:[],//热门城市
				
				//生活品质
				m2Ylink:[],//衣
				m2Slink:[],//食
				m2Zlink:[],//住
				m2Xlink:[],//行
				
				m3picList:[],//今日TOP榜单
				m3numList:[],//今日TOP榜单
				
				m4listArr1:null,
				m4listArr2:null,
				m4listArr3:null,
				m4list1:[],//行业十大排行
				m4list2:[],//行业十大排行
				m4list3:[],//行业十大排行
				maxClickNum: 0, // 最大点击次数
				lastLeft: 0, // 上次滑动距离
				clickNum: 0, // 点击次数
				rollCon:[{},{},{},{},{},{}],//滚动数组
				
				m5tabIndex:1,
				m5tab:[
					{
						id:1,
						name:"世界500强"
					},
					{
						id:2,
						name:"中国老字号"
					},
					{
						id:3,
						name:"驰名商标"
					}
				],
				
				m5list:[],
				
				m6tabIndex:1,
				m6tab:[
					{
						id:1,
						name:"旅游"
					},
					{
						id:2,
						name:"品玩"
					}
					
				],
				m6picList:[],
				
				m7list:[],
				
				m8list1:[
					{},{},{},{},{},{},{},{},
				],
				
				m8list2:[],//国家馆
				pageTitle:'',
			};
		},
	
		created() {
			this.initPage();
		},
		computed: {},
		mounted() {
			// this.currImgs = [this.images[0]];
			this.startChange();
			// document.title = this.pageTitle;
		},
		methods: {
			initPage(){
				this.getBanner()
				this.getHotPaihang()
				this.getHotPinpai()
				this.getCityPaihang()
				this.getShenghuo1()
				this.getShenghuo2()
				this.getShenghuo3()
				this.getShenghuo4()
				this.getTodayTop()
				this.getShida()
				this.getTuijian()
				this.getLvyou()
				this.getGuojia()
			},
			dotBtn(num){
				// console.log(num,'aaaaaaaaaaaa');
				this.index = num
			},
			//焦点轮播
			startChange() {
				var _this = this;
				setInterval(function () {
					if (_this.index < _this.banners.length - 1) {
						_this.index++
					} else {
						_this.index = 0
					}
					// console.log(_this.index)
					// _this.currImgs.splice(0, 1, _this.bannerArr[_this.index]);
				}, 4000);
			},
			bannerTxtLink(item){
				console.log(item.id,'行业ID')
				var id = Number(item.id)+ 9999
				this.$router.push({
					path: '/ranking',
					query: {industryId: id},
				});
			},
			bannerLink(item){
				console.log(item.brandId,'品牌ID')
				var id = Number(item.brandId)+9999
				this.$router.push({
					path: '/brand/detail',
					query: {
						brandId: id,
					},
				});
			},
			//行业链接
			hangyeLink(item){
				console.log(item.id,'行业ID')
				var id = Number(item.id)+ 9999
				this.$router.push({
					path: '/ranking',
					query: {industryId: id},
				});
			},

			//品牌跳转链接
			pinpaiLink(item) {
				console.log(item.brandId,'品牌ID')
				var id = Number(item.brandId)+9999
				this.$router.push({
					path: '/brand/detail',
					query: {
						brandId: id,
					},
				});
			},
			
			//城市跳转链接
			cityLink(item){
				console.log(item.id,'城市')
				var id = Number(item.id)+9999
				this.$router.push({
					path: '/ranking',
					query: {industryId: id},
				});
			},
			
		    //品质生活跳转
			lifeLink(item){
				console.log(item.id,'品质生活')
				var id = Number(item.id)+9999
				this.$router.push({
					path: '/ranking',
					query: {industryId: id},
				});
			},	

			//top跳转
			topLink(item){
				console.log(item,'top榜单跳转')
				var id = Number(item.id)+9999
				this.$router.push({
					path: '/articleDetail',
					query: {
						id: id,
						channelName:this.channelName,
					},
				});
			},
			
			//十大品牌跳转
			sdpjLink(item){
				console.log(item,'十大品牌跳转')
				var id = Number(item.industryId)+9999
				this.$router.push({
					path: '/ranking',
					query: {industryId: id},
				});
			},
			
			//热门品牌跳转
			hotppLink(item){
				console.log(item,'热门品牌跳转')
				var id = Number(item.brandId)+9999
				this.$router.push({
					path: '/brand/detail',
					query: {
						brandId: id,
					},
				});
			},

			//旅游跳转
			lyLick(item){
				console.log(item,'旅游跳转')
				var id = Number(item.id)+9999
				this.$router.push({
					path: '/ranking',
					query: {industryId: id},
				});
			},
			
			//旅游2跳转
			ly2Lick(item){
				console.log(item,'旅游跳转2')
				var id = Number(item.brandId)+9999
				this.$router.push({
					path: '/brand/detail',
					query: {
						brandId: id,
					},
				});
			},
			
			//国家跳转
			countryLink(item){
				console.log(item,'国家跳转')
				var id = Number(item.id)+9999
				this.$router.push({
					path: '/ranking',
					query: {industryId: id},
				});
			},

			//首页轮播图
			getBanner(){
				banner().then(res=>{
					console.log(res.data.data,'焦点图');
					this.banners = res.data.data
				})
			},

			//热门排行
			getHotPaihang(){
				hotPaihang().then(res=>{
					// console.log(res.data.data,'热门排行榜');
					this.m1leftLsit=res.data.data
				})
			},

			//热门品牌榜
			getHotPinpai(){
				hotPinpai({
					 type:this.m1cTabHd,
				}).then(res=>{
					console.log(res.data.data,'热门品牌榜');
					this.m1centerList=res.data.data
				})
			},


			//城市排行
			getCityPaihang(){
				cityPaihang().then(res=>{
					// console.log(res.data.data,'热门城市榜');
					this.m1ritghtLsit=res.data.data
				})
			},
			

			//品质生活 (1:衣,2:食,3:住,4:行)
			getShenghuo1(){
				shenghuo({
					type:1
				}).then(res=>{
					// console.log(res.data.data,'生活');
					this.m2Ylink=res.data.data
				})
			},
			
			getShenghuo2(){
				shenghuo({
					type:2
				}).then(res=>{
					// console.log(res.data.data,'生活');
					this.m2Slink=res.data.data
				})
			},

			getShenghuo3(){
				shenghuo({
					type:3
				}).then(res=>{
					// console.log(res.data.data,'生活');
					this.m2Zlink=res.data.data
				})
			},

			getShenghuo4(){
				shenghuo({
					type:4
				}).then(res=>{
					// console.log(res.data.data,'生活');
					this.m2Xlink=res.data.data
				})
			},

			//今日Top
			getTodayTop(){
				todayTop().then(res=>{
					console.log(res.data.data,'今日TOP榜单');
					var arr = res.data.data.slice(0, 4); // 只需要一次切片  
					var newArr = arr.map(function(item) {  
					    // 创建一个新的对象，避免直接修改原始对象  
					    var newItem = { ...item };  
					    newItem.images = [];  
					    if (item.images && item.images !== "" && item.images != null) {  
					        // 假设item.images是一个逗号分隔的字符串  
					        newItem.images = item.images.split(",");  
					    }  
					    return newItem;  
					});  
					// console.log(newArr,'今日Top前4');
					this.m3picList=newArr
					this.m3numList=res.data.data.slice(-12)
				})
			},

			//十大品牌
			getShida(){
				shida().then(res=>{
					// console.log(res.data.data,'十大品牌');
					var dataCon = res.data.data
					this.rollCon = dataCon
				})
			},
			
			//品牌推荐 1:世界500强,2:中华老字号,3:驰名商标
			getTuijian(){
				tuijian({
					type:this.m5tabIndex
				}).then(res=>{
					// console.log(res.data.data,'品牌推荐');
					this.m5list=res.data.data
				})
			},
			
			//旅游娱乐 1:旅游,2:品玩
			getLvyou(){
				lvyou({
					type:this.m6tabIndex
				}).then(res=>{
					// console.log(res.data.data,'旅游娱乐');
					var dataCon = res.data.data
					var m6picList = dataCon.slice(0, 4);
					var m7list = dataCon.slice(-3);
					this.m6picList= m6picList
					this.m7list = m7list
					console.log(m7list,"旅游排行")
					
				})
			},

			//国家地区馆
			getGuojia(){
				guojia().then(res=>{
					console.log(res.data.data,'国家地区馆');
					var dataCon = res.data.data
					var m8list1 = dataCon.slice(0, 8);
					var m8list2 = dataCon.slice(-5);
					this.m8list1 = m8list1
					this.m8list2 = m8list2
				})
			},

			

			//顶部二维码弹出层
			mobileBtn(){
				this.mobilePop = !this.mobilePop
				setTimeout(() => {
					this.mobilePop = false
				}, 3000);
			},
				
			//月榜,总榜 切换

			cTabHd(e){
				console.log(e.target.dataset.index)	
				const index = e.target.dataset.index
				this.m1cTabHd = index
				
				this.getHotPinpai()
			},

			//热门品牌推荐
			m5tabBtn(id){
				console.log(id)	
				this.m5tabIndex = id
				this.getTuijian()
			},
			
			//旅游娱乐
			m6tabBtn(id){
				this.m6tabIndex = id
				this.getLvyou()
			},

			// 点击右箭头（左侧滚动）
			rightClick() {
			  // alert(222);
			  // 如果点击次数小于数组长度-1时，执行左滑动效果。
			  if (this.clickNum < this.rollCon.length - 3) {
			    let width = document.querySelectorAll('.roll-item')[this.clickNum]
			      .offsetWidth;
			    let lastItemOffsetLeft = document.getElementsByClassName('roll-item')[
			      this.rollCon.length - 1
			    ].offsetLeft;
			    const lookWidth = document.getElementById('classifyScroll').clientWidth;
			    if (lastItemOffsetLeft > lookWidth) {
			      document.getElementsByClassName('classifys')[0].style.marginLeft = `${
			        -width + this.lastLeft - 22
			      }px`;
			      this.lastLeft = -width + this.lastLeft - 22;
			      this.clickNum += 1;
			      this.maxClickNum = this.clickNum;
			    }
			  
			    // this.showRightIcon = lastItemOffsetLeft > lookWidth + width;
			  }
			},
			
			// 点击左箭头（右侧滚动）
			leftClick() {
			  // alert(111);
			  console.log(this.lastLeft, 'lastLeft')
			  // console.log(document.querySelectorAll('.roll-item')[this.clickNum - 1].offsetWidth, '上一个的宽度')
			  if (this.clickNum > 0) {
			    let width = document.querySelectorAll('.roll-item')[this.clickNum - 1]
			      .offsetWidth;
			    document.getElementsByClassName('classifys')[0].style.marginLeft = `${
			      this.lastLeft + width + 22
			    }px`;
			    this.lastLeft = width + this.lastLeft +22;
			    this.clickNum--;
			    // if (this.clickNum < this.maxClickNum) {
			    //   this.showRightIcon = true;
			    // }
			  }
			},

		},
	}
</script>

<style scoped>
	@import "../../assets/css/index.css";
	.container,
	.i-main {
		background: #f7f8fc;
	}
	
</style>